<template>
  <div v-if="!success" class="col">
        <input class="newsletter" type="email" id="email" name="email" placeholder="Your email" v-model="emailCollected"/>
        <button class="dark full " @click="requestSubscription">Subscribe</button>
        <p class="red no-m">{{error}}</p>
      </div>
      <div v-else class="centered">
        <h4>You are subscribed! :)</h4>
        <button v-if="backToWebsite" class="dark" @click="goHome()"> back to website</button>
        <p v-if="!backToWebsite" class="mb-1"></p>
      </div>
</template>

<script>
import router from "@/router/index.js";

export default {
  name: "NewsletterSignupBlock",
  props: ['backToWebsite'],
  data() {
    return {
      emailCollected: '',
      success: false,
      error: '',
    }
  },
  methods: {
    async requestSubscription() {
      var response = await this.$store.dispatch('addNewsletterSubscriber', this.emailCollected);
      console.log(response);
      if (response.success) {
         this.success = true;
      } else {
        this.error = response.error.body.message;
      }
    },
    goHome() {
      router.push('/home')
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

input.newsletter {
  padding: 1em;
  font-size: 1em;
  border-radius: 20px;
  border: 1px $blue solid;
}

</style>