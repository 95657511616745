<script>
export default {
  name: "NewsletterSVG"
}
</script>

<template>
<svg class="hover-teal" width="100%" height="100%" viewBox="0 0 667 646" fill="#2E4F9C">
    <g transform="matrix(1,0,0,1,-658.839,-446.743)">
        <path d="M1069.66,766.619C1071.32,769.121 1073.96,775.788 1075.69,786.115C1080.11,812.645 1084.28,874.19 1082.82,929.282C1081.95,962.462 1078.93,993.352 1073.08,1013.5C1069.73,1025.04 1065.14,1033.69 1060.2,1039.05C1050.5,1049.57 1031.55,1058.31 1008.13,1063.98C961.974,1075.15 897.391,1076.94 875.469,1076.9C853.605,1076.86 788.683,1081.95 741.457,1071.19C716.874,1065.59 696.822,1055.32 685.952,1040.46C675.876,1026.69 670.067,998.429 669,964.709C666.755,893.815 681.776,797.634 686.738,786.025C689.205,780.254 690.945,774.864 695.62,769.592C700.369,764.235 708.191,758.413 724.751,754.139C747.772,748.199 793.842,743.871 889.599,743.348C977.236,742.87 1014.63,740.35 1033.86,743.261C1053.12,746.176 1060.38,752.654 1069.66,766.619ZM1038.17,785.471C1037.04,783.764 1036.03,782.345 1034.61,781.342C1031.44,779.101 1026.96,779.07 1019.19,778.699C997.966,777.686 960.856,779.626 889.799,780.014C816.674,780.413 773.887,782.875 748.448,786.78C740.371,788.02 734.266,789.366 729.618,790.885C726.263,791.981 724.154,792.595 723.065,793.906C721.401,795.91 721.386,798.256 720.454,800.437C715.927,811.026 703.6,898.885 705.648,963.548C706.42,987.936 708.258,1008.85 715.545,1018.81C719.471,1024.18 726.036,1027.75 733.73,1030.72C743.823,1034.62 755.794,1037.08 768.382,1038.7C809.914,1044.07 857.742,1040.2 875.531,1040.24C893.178,1040.27 940.268,1039.13 980.657,1032.18C995.78,1029.59 1009.93,1026.22 1020.88,1021.62C1026.1,1019.42 1030.5,1017.17 1033.25,1014.19C1036.38,1010.8 1037.94,1004.1 1039.75,995.742C1043.61,977.976 1045.49,953.89 1046.17,928.314C1047.71,869.937 1042.92,803.846 1038.23,785.698C1038.21,785.628 1038.19,785.551 1038.17,785.471Z"/>
    </g>
    <g transform="matrix(1,0,0,1,-658.839,-446.743)">
        <path d="M885.033,924.087C888.726,921.565 893.863,917.112 900.192,911.613C942.291,875.031 1021.33,792.409 1035.77,770.026C1035.79,769.993 1035.82,769.958 1035.84,769.922C1037.7,763.119 1041.46,773.403 1048.88,772.309C1058.89,770.833 1061.19,771.177 1062.67,781.187L1071.64,777.97L1070.31,782.886C1069,786.26 1064.96,792.8 1058.27,801.198C1032.73,833.219 963.325,905.329 924.242,939.29C911.398,950.45 901.016,957.706 895.596,959.917L887.914,961.67L878.743,960.872C871.711,959.27 861.574,954.375 849.759,946.514C795.114,910.158 690.653,806.215 690.653,806.215C683.487,799.071 699.526,805.901 706.669,798.735C713.813,791.569 709.373,773.103 716.539,780.247C716.539,780.247 794.121,857.627 849.072,900.62C862.438,911.077 879.464,920.944 885.033,924.087Z"/>
    </g>
    <g transform="matrix(0.911315,0.41171,-0.41171,0.911315,-345.633,-883.326)">
        <path d="M1142.75,693.193C1138.36,694.639 1121.33,702.324 1105.48,737.716L1102.72,742.274L1098.77,746.03L1093.33,748.729L1086.61,749.555L1080.99,748.369L1075.49,745.196L1070.77,739.766L1067.03,731.014C1063.01,717.541 1059.51,684.369 1067.37,652.381C1071.97,633.645 1080.42,615.452 1094.08,601.259C1089.43,579.755 1083.72,533.772 1108.47,497.949C1122.97,476.961 1147.56,458.743 1189.34,451.501C1230.34,444.395 1260.51,451.543 1282.74,464.823C1335.35,496.259 1343.7,564.882 1342.34,581.872C1340.44,605.634 1337.28,673.277 1251.56,704.961C1233.39,711.675 1210.91,711.081 1190.46,707.295C1169.43,703.4 1150.76,696.431 1142.75,693.193ZM1100.02,678.21C1126.29,650.054 1149.53,656.706 1149.53,656.706C1150.91,656.971 1152.25,657.394 1153.53,657.965C1153.53,657.965 1173.58,666.878 1197.14,671.241C1211.09,673.824 1226.45,675.149 1238.85,670.568C1302.44,647.062 1304.38,596.579 1305.79,578.95C1306.75,566.967 1301.04,518.472 1263.93,496.299C1247.7,486.604 1225.53,482.441 1195.61,487.629C1166.51,492.671 1148.73,504.178 1138.63,518.791C1114.7,553.434 1131.9,601.474 1131.9,601.474C1134.44,608.877 1132,617.073 1125.83,621.88C1113.27,631.66 1106.61,646.329 1102.98,661.131C1101.57,666.844 1100.62,672.606 1100.02,678.21Z"/>
    </g>
    <g transform="matrix(0.911315,0.41171,-0.41171,0.911315,-348.027,-878.027)">
        <path d="M1212.41,572.703C1208.24,572.977 1205.5,570.536 1205.03,570.12C1204.05,569.248 1203.21,568.174 1202.63,566.996C1201.29,564.259 1200.74,556.279 1200.68,555.335C1200.67,555.239 1199.06,539.054 1203.9,508.964C1204.58,504.767 1205.17,501.719 1205.21,501.649C1206.4,499.585 1207.22,497.257 1208.79,495.463C1210.31,493.723 1212.23,492.282 1214.3,491.253C1216.37,490.225 1218.67,489.566 1220.98,489.403C1221,489.402 1228.09,490.283 1228.09,490.288C1230.14,491.477 1232.46,492.295 1234.25,493.858C1235.98,495.379 1237.42,497.296 1238.45,499.362C1239.48,501.433 1240.14,503.74 1240.31,506.046C1240.6,510.063 1240.93,510.222 1239.11,513.818C1234.56,522.795 1223.86,546.992 1222.09,561.496C1221.59,565.55 1221.59,565.554 1221.48,565.889C1220.11,569.832 1216.9,571.391 1216.35,571.661C1214.49,572.563 1214.47,572.567 1212.41,572.703Z"/>
    </g>
    <g transform="matrix(0.911315,0.41171,-0.41171,0.911315,-345.633,-883.326)">
        <path d="M1194.09,612.836C1192.28,622.187 1205.61,636.365 1222.33,630.077C1239.05,623.789 1238.2,605.144 1230.25,599.278C1222.29,593.413 1217.73,591.711 1211.73,592.797C1204.15,594.168 1202.31,595.272 1199.24,600.105C1196.69,604.135 1194.97,608.249 1194.09,612.836Z" />
    </g>
</svg>

</template>

<style scoped lang="scss">

</style>